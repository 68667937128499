@font-face {
  font-family: "BDO Grotesk";
  src: url("/fonts/BDOGrotesk-Regular.woff") format("woff");
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

@font-face {
  font-family: "BDO Grotesk";
  src: url("/fonts/BDOGrotesk-Medium.woff") format("woff");
  font-weight: 500; /* Bold weight */
  font-style: normal;
}

@font-face {
  font-family: "BDO Grotesk";
  src: url("/fonts/BDOGrotesk-DemiBold.woff2") format("woff2");
  font-weight: 600; /* DemiBold weight */
  font-style: normal;
}

@font-face {
  font-family: "Ginka";
  src:
    url("/fonts/Ginka-Light.woff") format("woff"),
    url("/fonts/Ginka-Light.woff2") format("woff2");
  font-weight: 300; /* Regular weight */
  font-style: normal;
}

@font-face {
  font-family: "Ginka";
  src:
    url("/fonts/Ginka-Regular.woff") format("woff"),
    url("/fonts/Ginka-Regular.woff2") format("woff2");
  font-weight: 400; /* Regular weight */
  font-style: normal;
}
