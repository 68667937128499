@keyframes fadeText {
  0% {
    color: rgba(216, 233, 247, 1);
  }
  50% {
    color: rgba(100, 101, 146, 1);
  }
  100% {
    color: rgba(16, 59, 93, 1);
  }
}

@keyframes moveText {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.wave-animated-text {
  background: linear-gradient(
    to right,
    rgba(216, 233, 247, 1),
    rgba(87, 146, 179, 1),
    rgba(16, 59, 93, 1),
    rgba(87, 146, 179, 1),
    rgba(216, 233, 247, 1)
  );
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: moveText 3s linear infinite;
}

/* Rotate element */
.rotate-cw {
  animation: rotateCW 2s linear infinite; /* Animate rotation */
}

.rotate-ccw {
  animation: rotateCCW 2s linear infinite; /* Animate rotation */
}

@keyframes rotateCW {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateCCW {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
