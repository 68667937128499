.highlight-border {
  outline: 0.25rem solid #53b1fd99;
  outline-offset: 0.25rem;
  border-radius: 0.5rem;
  z-index: 1000000;
}

.shepherd-custom-z-index {
  z-index: 100 !important;
}

.shepherd-text {
  color: #ffffff !important;
  padding: 0 !important;
}

.shepherd-footer {
  width: 100%;
  padding: 0 !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-top: 1rem;
}

.shepherd-button {
  background: transparent !important;
  color: #f0f5fa !important;
  text-shadow: none !important;
  padding: 0.5rem 1rem !important;
  font-size: 0.8125rem !important;
  font-weight: 600 !important;
  line-height: 0.975rem !important;
  transition: none !important;
  border-radius: 0.5rem !important;
}

.shepherd-button:hover {
  background: #ebe9fe33 !important;
  transition: none !important;
}

.shepherd-theme-custom {
  border-radius: 0.5rem !important;
  background-color: #203d54 !important;
  width: unset !important;
  padding: 1rem !important;
}

.shepherd-theme-custom h1 {
  color: #ffffff;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.375rem;
  margin: 0;
  margin-bottom: 1rem !important;
  padding: 0;
}

.shepherd-theme-custom p {
  color: #ffffff;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.375rem;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem !important;
}

.shepherd-theme-custom ul {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem !important;
  gap: 0.5rem;
}

.shepherd-theme-custom li {
  color: #ffffff;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.shepherd-progress {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.shepherd-progress-text {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: #f0f5fa;
}

.shepherd-progress-backBar {
  position: relative;
  width: 10rem;
  height: 0.5rem;
  background-color: #f0f5fa33;
  border-radius: 0.25rem;
  overflow: hidden;
}

.shepherd-progress-frontBar {
  position: absolute;
  height: 0.5rem;
  background-color: #f0f5fa;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
